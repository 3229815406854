<template>
<div class="tw-flex tw-w-full tw-flex-col tw-px-4 lg:tw-px-20">

  <booking-steps />
  <div class="back tw-mb-8" @click="$router.push({name:'RoundTripInputTravellerDetails'})"><v-icon>mdi-chevron-left</v-icon>Back</div>
  <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between">
   <div class="tw-flex tw-w-full lg:tw-w-7/12 tw-flex-col">
    <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
      <label class="note">Travel Details</label>
      <label class="note">Total Price</label>
    </div>
     <div class="tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-justify-between tw-items-start md:tw-items-center">
       <div class="tw-flex tw-flex-col">
         <div class="tw-flex tw-flex-row tw-items-center tw-py-4">
           <h6 class="to-and-fro">Lagos</h6>
           <img src="@/assets/to_and_fro.svg" alt="to and fro" class="tw-mx-4">
           <h6 class="to-and-fro">Abuja</h6>
         </div>
         <label class="to-and-fro-date">Saturday July 5th - Saturday July 12th   </label>
         <label class="passenger-class tw-py-3">Return trip  . 2 Adults . Economy </label>
       </div>
       <div class="tw-flex tw-flex-col">
         <label class="total-price tw-py-6">&#8358;25,000</label>
         <v-btn text class="make-payment">Make Payment</v-btn>
       </div>
     </div>

     <div class="tw-flex tw-w-full  tw-flex-col tw-py-8">
       <h6 class="header">Departure</h6>
       <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-py-8">
         <img class="company-logo" :src="departure.companyLogo" alt="logo"/>
         <v-col  class="tw-flex tw-flex-row tw-items-center">
           <h6 class="company-name tw-ml-5 text-capitalize">{{departure.companyName}}</h6>
         </v-col>
         <v-col  class="tw-flex  tw-flex-row tw-items-center">
           <h6 class="trip-time tw-ml-5 text-uppercase">{{departure.departureState.substring(0,3)}} - {{departure.destinationState.substring(0,3)}}</h6>
         </v-col>
         <v-col  class="tw-hidden md:tw-flex  tw-flex-row tw-items-center">
           <h6 class="trip-time tw-ml-5 text-uppercase">{{departure.takeOffTime}}</h6>
         </v-col>
         <v-col  class="tw-hidden md:tw-flex  tw-flex-row tw-items-center">
           <h6 class="trip-time tw-ml-5 text-uppercase">&#8358; {{departure.amount}}</h6>
         </v-col>
       </div>

       <div class="tw-flex tw-w-full tw-justify-center tw-py-5">
         <div class="tw-flex  tw-flex-col tw-w-11/12 md:tw-w-10/12 ">
           <div class="tw-flex md:tw-hidden tw-w-full tw-justify-between tw-py-5">
             <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}</h6>
             <h6 class="trip-time text-uppercase" style="color: #004aad; font-size: 20px">&#8358; {{departure.amount}}</h6>
           </div>
           <div class="tw-w-full tw-flex tw-items-center">
             <icons name="to-and-fro" color="#959595" class="tw-mr-5" />
             <div class="tw-w-11/12 tw-flex-col">
               <div class="tw-flex tw-w-full tw-justify-between tw-items-center">

                 <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}
                   <span class="trip-time text-capitalize tw-pl-3">{{departure.departureState}} ({{departure.departureCity}} - {{departure.departureTerminalAddress}})</span>
                 </h6>
                 <h6 class="trip-time text-uppercase tw-hidden md:tw-flex" style="color: #004aad; font-size: 20px">&#8358; {{departure.amount}}</h6>
               </div>
               <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-8">
                 <h6 class="trip-time text-capitalize">5 hours (Approx) 2 stops</h6>

                 <h6 class="trip-time  tw-hidden md:tw-flex" >Seat(s): 10,4</h6>
               </div>
               <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-8">
                 <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}
                   <span class="trip-time text-capitalize tw-pl-3">
                  {{departure.departureState}} ({{departure.departureCity}} - {{departure.departureTerminalAddress}})
                </span>
                 </h6>
               </div>
             </div>

           </div>
           <h6 class="trip-time  tw-flex md:tw-hidden tw-mt-5" >Seat(s): 3,3</h6>
           <h6 class="tips tw-mt-10">Greener Line  .  Coaster Bus  .  Economy  . BUS 567 </h6>

         </div>
       </div>
     </div>

     <v-divider/>

     <div class="tw-flex tw-w-full  tw-flex-col tw-py-8">
       <h6 class="header">Return</h6>
       <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-py-8">
         <img class="company-logo" :src="departure.companyLogo" alt="logo"/>
         <v-col  class="tw-flex tw-flex-row tw-items-center">
           <h6 class="company-name tw-ml-5 text-capitalize">{{departure.companyName}}</h6>
         </v-col>
         <v-col  class="tw-flex  tw-flex-row tw-items-center">
           <h6 class="trip-time tw-ml-5 text-uppercase">{{departure.departureState.substring(0,3)}} - {{departure.destinationState.substring(0,3)}}</h6>
         </v-col>
         <v-col  class="tw-hidden md:tw-flex  tw-flex-row tw-items-center">
           <h6 class="trip-time tw-ml-5 text-uppercase">{{departure.takeOffTime}}</h6>
         </v-col>
         <v-col  class="tw-hidden md:tw-flex  tw-flex-row tw-items-center">
           <h6 class="trip-time tw-ml-5 text-uppercase">&#8358; {{departure.amount}}</h6>
         </v-col>
       </div>

       <div class="tw-flex tw-w-full tw-justify-center tw-py-5">
         <div class="tw-flex  tw-flex-col tw-w-11/12 md:tw-w-10/12 ">
           <div class="tw-flex md:tw-hidden tw-w-full tw-justify-between tw-py-5">
             <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}</h6>
             <h6 class="trip-time text-uppercase" style="color: #004aad; font-size: 20px">&#8358; {{departure.amount}}</h6>
           </div>
           <div class="tw-w-full tw-flex tw-items-center">
             <icons name="to-and-fro" color="#959595" class="tw-mr-5" />
             <div class="tw-w-11/12 tw-flex-col">
               <div class="tw-flex tw-w-full tw-justify-between tw-items-center">

                 <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}
                   <span class="trip-time text-capitalize tw-pl-3">{{departure.departureState}} ({{departure.departureCity}} - {{departure.departureTerminalAddress}})</span>
                 </h6>
                 <h6 class="trip-time text-uppercase tw-hidden md:tw-flex" style="color: #004aad; font-size: 20px">&#8358; {{departure.amount}}</h6>
               </div>
               <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-8">
                 <h6 class="trip-time text-capitalize">5 hours (Approx) 2 stops</h6>

                 <h6 class="trip-time  tw-hidden md:tw-flex" >Seat(s): 2,3</h6>
               </div>
               <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-8">
                 <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}
                   <span class="trip-time text-capitalize tw-pl-3">
                  {{departure.departureState}} ({{departure.departureCity}} - {{departure.departureTerminalAddress}})
                </span>
                 </h6>
               </div>
             </div>

           </div>
           <h6 class="trip-time text-capitalize tw-flex md:tw-hidden tw-mt-5" >Available: 2</h6>
           <h6 class="tips tw-mt-10">Greener Line  .  Coaster Bus  .  Economy  . BUS 567 </h6>

         </div>
       </div>
     </div>

   </div>
    <div class="tw-flex tw-w-full lg:tw-w-3/12 tw-items-center check-out-card tw-flex-col">
      <select-insurance-plan/>
      <checkout-passenger-summary/>
      <div class="tw-flex tw-px-6 tw-pb-4 tw-w-full">
        <base-button button-text="Make Payment" width="100%" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import BookingSteps from "@/components/reuseables/bookingAndHiring/BookingSteps";
import SelectInsurancePlan from "@/views/bookings/SelectInsurancePlan";
import CheckoutPassengerSummary from "@/views/bookings/CheckoutPassengerSummary";
import BaseButton from "@/components/reuseables/BaseButton";
import companyLogo from "@/assets/bustriptwo.png";
import Icons from "@/components/reuseables/Icons";

export default {
  name: "CheckOutView",
  components: {Icons, BaseButton, CheckoutPassengerSummary, SelectInsurancePlan, BookingSteps},
  data(){
    return{
      insuranceOptions:[
        {
          name: "Family Plan",
          amount: 1000
        },
        {
          name: "Economy Plan",
          amount: 3000
        },
        {
          name: "Premium Plan",
          amount: 5000
        }
      ],
      selectedInsurance :{},
      departure:{
        id : 1,
        companyLogo : companyLogo,
        companyName: "Greener Line",
        departureState: "Lagos",
        departureCity: "Yaba",
        departureTerminalAddress: "Ognenevo",
        destinationState: "Delta",
        destinationCity: "Effurun",
        destinationTerminalAddress: "44, Effurun roadabout, Effurun",
        takeOffTime : "10:30AM",
        amount : "9,900",
      },
    }
  }
}
</script>

<style scoped lang="scss">
.check-out-card{
  background-size: cover;
  background: white ;
  box-shadow: 4px 4px 35px rgba(79, 79, 79, 0.15);
  height: max-content;
}

.check-out-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #004AAD;
}

.insurance-details{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: rgba(0, 17, 39, 0.73);
}

.select-insurance{
  background: #F2F2F4;
  border: 1px dashed #000000;
  height: max-content;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.note{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 32px;
  color: #83868A;
}

.to-and-fro{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #4F4F4F;
}

.to-and-fro-date{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.back{
  display: flex;
  align-items: center;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
  width: max-content;
  text-transform: none;
  cursor: pointer;
}
.passenger-class{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(79, 79, 79, 0.71);
}

.total-price{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #004AAD;
}

.make-payment{
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 1rem;
  height: 41px;
  border: 1px solid #004AAD;
  border-radius: 4px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #004AAD;
  text-transform: none;

}

.header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #004AAD;
}

.company-logo{
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.company-name{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
  flex-wrap: nowrap;
}

.trip-time{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.tips{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #878787;
}
</style>